var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ul',{staticClass:"menu-nav"},[_c('router-link',{attrs:{"to":"/dashboard"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var href = ref.href;
var navigate = ref.navigate;
var isActive = ref.isActive;
var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
          isActive && 'menu-item-active',
          isExactActive && 'menu-item-active'
        ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-dashboard.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("DASHBOARD")])],1)])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/master/master')
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-master.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("MASTER")]),_c('i',{staticClass:"menu-arrow"})],1),(_vm.user_role.toLowerCase() == 'master keuangan')?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('vul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/master/currency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("MATA UANG")])],1)])]}}],null,false,1823141681)}),_c('router-link',{attrs:{"to":"/master/bank"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-bank.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("BANK")])],1)])]}}],null,false,3077847009)}),_c('router-link',{attrs:{"to":"/master/bank-account"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-account-number.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("REKENING BANK")])],1)])]}}],null,false,4025805119)}),_c('router-link',{attrs:{"to":"/master/cheque"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-cheque.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("CEK")])],1)])]}}],null,false,1567818659)}),_c('router-link',{attrs:{"to":"/master/financings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PEMBIAYAAN / COST")])],1)])]}}],null,false,4121861940)}),_c('router-link',{attrs:{"to":"/master/exchange-rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KURS")])],1)])]}}],null,false,416323786)}),_c('router-link',{attrs:{"to":"/master/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-organizational-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("USERS")])],1)])]}}],null,false,183081389)})],1)],1):(_vm.user_role.toLowerCase() == 'atasan keuangan')?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/master/currency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("MATA UANG")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/bank"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-bank.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("BANK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/bank-account"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-account-number.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("REKENING BANK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/cheque"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-cheque.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("CEK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/financings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PEMBIAYAAN / COST")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/exchange-rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KURS")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-organizational-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("USERS")])],1)])]}}])})],1)]):(_vm.user_role.toLowerCase() == 'keuangan kas kecil')?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"})]):(_vm.user_role.toLowerCase() == 'operasional')?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/master/organizational-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-organizational-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("UNIT MATRA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("SATUAN/NON SATUAN KERJA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/sub-work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("SUB SATUAN/NON SATUAN KERJA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/part-of-sub-work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("RUANGAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/positions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("JABATAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/workgroup"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-workgroup.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PERUSAHAAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/division"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-division.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("DIVISI")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/project"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-project.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PROYEK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-organizational-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("USERS")])],1)])]}}])})],1)]):(_vm.user_role.toLowerCase() == 'atasan operasional')?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/master/organizational-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-organizational-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("UNIT MATRA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("SATUAN/NON SATUAN KERJA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/sub-work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("SUB SATUAN/NON SATUAN KERJA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/part-of-sub-work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("RUANGAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/positions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("JABATAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/workgroup"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-workgroup.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PERUSAHAAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/division"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-division.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("DIVISI")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/project"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-project.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PROYEK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-organizational-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("USERS")])],1)])]}}])})],1)]):(_vm.user_role.toLowerCase() == 'admin')?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/master/bank"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-bank.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("BANK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/cheque"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-cheque.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("CEK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/division"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-division.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("DIVISI")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/positions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("JABATAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/exchange-rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KURS")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/financings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PEMBIAYAAN / COST")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/currency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("MATA UANG")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/project"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-project.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PROYEK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/workgroup"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-workgroup.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PERUSAHAAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/bank-account"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-account-number.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("REKENING BANK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("SATUAN/NON SATUAN KERJA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/sub-work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("SUB SATUAN/NON SATUAN KERJA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/part-of-sub-work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("RUANGAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/organizational-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-organizational-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("UNIT MATRA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-organizational-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("USERS")])],1)])]}}])})],1)]):(_vm.user_role.toLowerCase() == 'atasan')?_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/master/bank"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-bank.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("BANK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/cheque"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-cheque.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("CEK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/division"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-division.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("DIVISI")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/positions"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("JABATAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/exchange-rate"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KURS")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/financings"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PEMBIAYAAN / COST")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/currency"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-exchange-rate.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("MATA UANG")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/project"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-project.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PROYEK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/workgroup"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-workgroup.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("PERUSAHAAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/bank-account"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-account-number.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("REKENING BANK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("SATUAN/NON SATUAN KERJA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/sub-work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("SUB SATUAN/NON SATUAN KERJA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/part-of-sub-work-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-work-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("RUANGAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/organizational-units"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-organizational-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("UNIT MATRA")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/master/users"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-organizational-units.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("USERS")])],1)])]}}])})],1)]):_vm._e()]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/kas/kas')
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-large-cashbank.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KAS BESAR")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/cashbank/large-cashbank/cashbank-in"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-cashbank-in.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KAS MASUK")])],1)])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/kas/kas-besar/kas-keluar')
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-cashbank-out.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KAS KELUAR")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/cashbank/large-cashbank/cashbank-out/internal/voucher"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-internal.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("INTERNAL")])],1)])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/kas/kas-keluar/operational'
                    )
                  },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-operational.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("OPERASIONAL")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/cashbank/large-cashbank/cashbank-out/operational/voucher"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'
                          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-voucher.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("VOUCHER")])],1)])]}}])})],1)])])],1)])]),_c('router-link',{attrs:{"to":"/cashbank/large-cashbank/transaction-reports"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var href = ref.href;
                          var navigate = ref.navigate;
                          var isActive = ref.isActive;
                          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-cashbank-in.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("LAPORAN TRANSAKSI")])],1)])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/kas/kas')
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-small-cashbank.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KAS KECIL")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/cashbank/small-cashbank/cashbank-in/voucher"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-cashbank-in.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KAS MASUK")])],1)])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/kas/kas-kecil/kas-keluar')
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-cashbank-out.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KAS KELUAR")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/cashbank/small-cashbank/cashbank-out/internal/voucher"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-internal.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("INTERNAL")])],1)])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/kas/kas-keluar/operational'
                    )
                  },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-operational.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("OPERASIONAL")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/cashbank/small-cashbank/cashbank-out/operational/voucher"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'
                          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-voucher.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("VOUCHER")])],1)])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/kas/kas-keluar/admin-project'
                    )
                  },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-admin-project.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("ADMIN PROYEK")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/cashbank/small-cashbank/cashbank-out/admin-project/voucher"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'
                          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-voucher.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("VOUCHER")])],1)])]}}])})],1)])])],1)])]),_c('router-link',{attrs:{"to":"/cashbank/small-cashbank/transaction-reports"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var href = ref.href;
                          var navigate = ref.navigate;
                          var isActive = ref.isActive;
                          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-cashbank-in.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("LAPORAN TRANSAKSI")])],1)])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/bank-in-out/bank-in-out')
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-bank-transaction.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("TRANSAKSI BANK")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/bank-in-out/bank-in"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var href = ref.href;
      var navigate = ref.navigate;
      var isActive = ref.isActive;
      var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-bank-in.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("BANK MASUK")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/bank-in-out/bank-out"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var href = ref.href;
              var navigate = ref.navigate;
              var isActive = ref.isActive;
              var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-bank-out.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("BANK KELUAR")])],1)])]}}])}),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/bank-guarantee/internal')
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-bank-guarantee.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("BANK GARANSI INTERNAL")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-submenu",class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/bank-guarantee/internal/submission-form'
                    )
                  },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-submission-form.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("FORM PENGAJUAN")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/bank-guarantee/internal/submission-form/operational"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'
                          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-operational.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("OPERASIONAL")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/bank-guarantee/internal/submission-form/finance"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var href = ref.href;
                          var navigate = ref.navigate;
                          var isActive = ref.isActive;
                          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'
                          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-finance.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KEUANGAN")])],1)])]}}])})],1)])])])])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/bank-guarantee/external')
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-bank-guarantee.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("BANK GARANSI EKSTERNAL")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-submenu",class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/bank-guarantee/internal/submission-form'
                    )
                  },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-internal.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("UANG KELUAR")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/bank-guarantee/external/internal/submission-form/operational"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'
                          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-operational.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("FORM PENGAJUAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/bank-guarantee/external/internal/voucher"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var href = ref.href;
                          var navigate = ref.navigate;
                          var isActive = ref.isActive;
                          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'
                          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-internal.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("VOUCHER")])],1)])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
                    'menu-item-open': _vm.hasActiveChildren(
                      '/bank-guarantee/external/submission-form'
                    )
                  },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-external.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("UANG MASUK")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/bank-guarantee/external/external/submission-form/operational"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var href = ref.href;
                  var navigate = ref.navigate;
                  var isActive = ref.isActive;
                  var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'
                          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-operational.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("FORM PENGAJUAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/bank-guarantee/external/external/voucher"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var href = ref.href;
                          var navigate = ref.navigate;
                          var isActive = ref.isActive;
                          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                            isActive && 'menu-item-active',
                            isExactActive && 'menu-item-active'
                          ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-external.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("VOUCHER")])],1)])]}}])})],1)])])])])]),_c('router-link',{attrs:{"to":"/bank-guarantee/guarantee/guarantee"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                          var href = ref.href;
                          var navigate = ref.navigate;
                          var isActive = ref.isActive;
                          var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                isActive && 'menu-item-active',
                isExactActive && 'menu-item-active'
              ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-bank-guarantee.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("BANK GARANSI")])],1)])]}}])})],1)])]),_c('li',{staticClass:"menu-item menu-item-submenu",class:{
        'menu-item-open': _vm.hasActiveChildren('/operational/submission-form')
      },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-operational.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("OPERASIONAL")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('li',{staticClass:"menu-item menu-item-submenu",class:{
              'menu-item-open': _vm.hasActiveChildren('/submission-form')
            },attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link menu-toggle",attrs:{"href":"#"}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-submission-form.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("FORM PENGAJUAN")]),_c('i',{staticClass:"menu-arrow"})],1),_c('div',{staticClass:"menu-submenu"},[_c('span',{staticClass:"menu-arrow"}),_c('ul',{staticClass:"menu-subnav"},[_c('router-link',{attrs:{"to":"/cashbank/large-cashbank/cashbank-out/operational/submission-form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
            var isActive = ref.isActive;
            var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-operational.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("EOP")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/cashbank/small-cashbank/cashbank-out/operational/submission-form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-operational.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("SOSIALISASI")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/cashbank/small-cashbank/cashbank-out/admin-project/submission-form"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-operational.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("ADMINISTRASI")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/field-activities"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-operational.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("KEGIATAN LAPORAN")])],1)])]}}])}),_c('router-link',{attrs:{"to":"/operational-history"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var href = ref.href;
                    var navigate = ref.navigate;
                    var isActive = ref.isActive;
                    var isExactActive = ref.isExactActive;
return [_c('li',{staticClass:"menu-item",class:[
                      isActive && 'menu-item-active',
                      isExactActive && 'menu-item-active'
                    ],attrs:{"aria-haspopup":"true","data-menu-toggle":"hover"}},[_c('a',{staticClass:"menu-link",attrs:{"href":href},on:{"click":navigate}},[_c('v-img',{staticClass:"mr-4 mt-2",attrs:{"max-width":"22","max-height":"22","src":require("../../../../public/assets/icons/icon-operational.png")}}),_c('span',{staticClass:"menu-text"},[_vm._v("RIWAYAT PEJABAT")])],1)])]}}])})],1)])])])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }