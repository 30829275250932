<template>
  <div>
    <ul class="menu-nav">
      <!-- router link dashboard -->
      <router-link
        to="/dashboard"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          class="menu-item"
          :class="[
            isActive && 'menu-item-active',
            isExactActive && 'menu-item-active'
          ]"
        >
          <a :href="href" class="menu-link" @click="navigate">
            <v-img
              max-width="22"
              max-height="22"
              src="../../../../public/assets/icons/icon-dashboard.png"
              class="mr-4"
            ></v-img>
            <span class="menu-text">DASHBOARD</span>
          </a>
        </li>
      </router-link>
      <!-- router link master -->
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/master/master')
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <v-img
            max-width="22"
            max-height="22"
            src="../../../../public/assets/icons/icon-master.png"
            class="mr-4"
          ></v-img>
          <span class="menu-text">MASTER</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu" v-if="user_role.toLowerCase() == 'master keuangan'">
          <span class="menu-arrow"></span>
          <vul class="menu-subnav">
            <!-- router link master currency -->
            <router-link
              to="/master/currency"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">MATA UANG</span>
                </a>
              </li>
            </router-link>
            <!-- router link master bank -->
            <router-link
              to="/master/bank"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-bank.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">BANK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master bank account -->
            <router-link
              to="/master/bank-account"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-account-number.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">REKENING BANK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master cheque -->
            <router-link
              to="/master/cheque"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-cheque.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">CEK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master financings -->
            <router-link
              to="/master/financings"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PEMBIAYAAN / COST</span>
                </a>
              </li>
            </router-link>
            <!-- router link master exchange rate -->
            <router-link
              to="/master/exchange-rate"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">KURS</span>
                </a>
              </li>
            </router-link>
            <!-- router link master users -->
            <router-link
              to="/master/users"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-organizational-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">USERS</span>
                </a>
              </li>
            </router-link>
          </vul>
        </div>
        <div class="menu-submenu" v-else-if="user_role.toLowerCase() == 'atasan keuangan'">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- router link master currency -->
            <router-link
              to="/master/currency"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">MATA UANG</span>
                </a>
              </li>
            </router-link>
            <!-- router link master bank -->
            <router-link
              to="/master/bank"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-bank.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">BANK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master bank account -->
            <router-link
              to="/master/bank-account"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-account-number.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">REKENING BANK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master cheque -->
            <router-link
              to="/master/cheque"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-cheque.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">CEK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master financings -->
            <router-link
              to="/master/financings"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PEMBIAYAAN / COST</span>
                </a>
              </li>
            </router-link>
            <!-- router link master exchange rate -->
            <router-link
              to="/master/exchange-rate"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">KURS</span>
                </a>
              </li>
            </router-link>
            <!-- router link master users -->
            <router-link
              to="/master/users"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-organizational-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">USERS</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
        <div class="menu-submenu" v-else-if="user_role.toLowerCase() == 'keuangan kas kecil'">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
          </ul>
        </div>
        <div class="menu-submenu" v-else-if="user_role.toLowerCase() == 'operasional'">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- router link master organizational units -->
            <router-link
              to="/master/organizational-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-organizational-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">UNIT MATRA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master work units -->
            <router-link
              to="/master/work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">SATUAN/NON SATUAN KERJA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master sub work units -->
            <router-link
              to="/master/sub-work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">SUB SATUAN/NON SATUAN KERJA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master room -->
            <router-link
              to="/master/part-of-sub-work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">RUANGAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master positions -->
            <router-link
              to="/master/positions"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">JABATAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master workgroup -->
            <router-link
              to="/master/workgroup"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-workgroup.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PERUSAHAAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master division -->
            <router-link
              to="/master/division"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-division.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">DIVISI</span>
                </a>
              </li>
            </router-link>
            <!-- router link master project -->
            <router-link
              to="/master/project"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-project.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PROYEK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master users -->
            <router-link
              to="/master/users"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-organizational-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">USERS</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
        <div class="menu-submenu" v-else-if="user_role.toLowerCase() == 'atasan operasional'">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- router link master organizational units -->
            <router-link
              to="/master/organizational-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-organizational-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">UNIT MATRA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master work units -->
            <router-link
              to="/master/work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">SATUAN/NON SATUAN KERJA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master sub work units -->
            <router-link
              to="/master/sub-work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">SUB SATUAN/NON SATUAN KERJA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master room -->
            <router-link
              to="/master/part-of-sub-work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">RUANGAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master positions -->
            <router-link
              to="/master/positions"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">JABATAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master workgroup -->
            <router-link
              to="/master/workgroup"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-workgroup.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PERUSAHAAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master division -->
            <router-link
              to="/master/division"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-division.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">DIVISI</span>
                </a>
              </li>
            </router-link>
            <!-- router link master project -->
            <router-link
              to="/master/project"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-project.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PROYEK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master users -->
            <router-link
              to="/master/users"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-organizational-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">USERS</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
        <div class="menu-submenu" v-else-if="user_role.toLowerCase() == 'admin'">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- router link master bank -->
            <router-link
              to="/master/bank"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-bank.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">BANK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master cheque -->
            <router-link
              to="/master/cheque"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-cheque.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">CEK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master division -->
            <router-link
              to="/master/division"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-division.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">DIVISI</span>
                </a>
              </li>
            </router-link>
            <!-- router link master positions -->
            <router-link
              to="/master/positions"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">JABATAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master exchange rate -->
            <router-link
              to="/master/exchange-rate"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">KURS</span>
                </a>
              </li>
            </router-link>
            <!-- router link master financings -->
            <router-link
              to="/master/financings"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PEMBIAYAAN / COST</span>
                </a>
              </li>
            </router-link>
            <!-- router link master activities -->
            <!-- <router-link
              to="/master/activities"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-dashboard.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">KEGIATAN</span>
                </a>
              </li>
            </router-link> -->
            <!-- router link master currency -->
            <router-link
              to="/master/currency"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">MATA UANG</span>
                </a>
              </li>
            </router-link>
            <!-- router link master project -->
            <router-link
              to="/master/project"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-project.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PROYEK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master workgroup -->
            <router-link
              to="/master/workgroup"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-workgroup.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PERUSAHAAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master bank account -->
            <router-link
              to="/master/bank-account"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-account-number.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">REKENING BANK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master work units -->
            <router-link
              to="/master/work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">SATUAN/NON SATUAN KERJA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master sub work units -->
            <router-link
              to="/master/sub-work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">SUB SATUAN/NON SATUAN KERJA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master room -->
            <router-link
              to="/master/part-of-sub-work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">RUANGAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master organizational units -->
            <router-link
              to="/master/organizational-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-organizational-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">UNIT MATRA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master users -->
            <router-link
              to="/master/users"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-organizational-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">USERS</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
        <div class="menu-submenu" v-else-if="user_role.toLowerCase() == 'atasan'">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- router link master bank -->
            <router-link
              to="/master/bank"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-bank.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">BANK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master cheque -->
            <router-link
              to="/master/cheque"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-cheque.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">CEK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master division -->
            <router-link
              to="/master/division"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-division.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">DIVISI</span>
                </a>
              </li>
            </router-link>
            <!-- router link master positions -->
            <router-link
              to="/master/positions"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">JABATAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master exchange rate -->
            <router-link
              to="/master/exchange-rate"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">KURS</span>
                </a>
              </li>
            </router-link>
            <!-- router link master financings -->
            <router-link
              to="/master/financings"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PEMBIAYAAN / COST</span>
                </a>
              </li>
            </router-link>
            <!-- router link master activities -->
            <!-- <router-link
              to="/master/activities"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-dashboard.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">KEGIATAN</span>
                </a>
              </li>
            </router-link> -->
            <!-- router link master currency -->
            <router-link
              to="/master/currency"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-exchange-rate.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">MATA UANG</span>
                </a>
              </li>
            </router-link>
            <!-- router link master project -->
            <router-link
              to="/master/project"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-project.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PROYEK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master workgroup -->
            <router-link
              to="/master/workgroup"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-workgroup.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">PERUSAHAAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master bank account -->
            <router-link
              to="/master/bank-account"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-account-number.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">REKENING BANK</span>
                </a>
              </li>
            </router-link>
            <!-- router link master work units -->
            <router-link
              to="/master/work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">SATUAN/NON SATUAN KERJA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master sub work units -->
            <router-link
              to="/master/sub-work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">SUB SATUAN/NON SATUAN KERJA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master room -->
            <router-link
              to="/master/part-of-sub-work-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-work-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">RUANGAN</span>
                </a>
              </li>
            </router-link>
            <!-- router link master organizational units -->
            <router-link
              to="/master/organizational-units"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-organizational-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">UNIT MATRA</span>
                </a>
              </li>
            </router-link>
            <!-- router link master users -->
            <router-link
              to="/master/users"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-organizational-units.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">USERS</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
      <!-- router link cashbank -->
      <!-- router link large-cashbank -->
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/kas/kas')
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <v-img
            max-width="22"
            max-height="22"
            src="../../../../public/assets/icons/icon-large-cashbank.png"
            class="mr-4"
          ></v-img>
          <span class="menu-text">KAS BESAR</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- router link large-cashbank -> cashbank in -->
            <router-link
              to="/cashbank/large-cashbank/cashbank-in"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-cashbank-in.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">KAS MASUK</span>
                </a>
              </li>
            </router-link>
            <!-- router link large-cashbank -> cashbank out -->
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/kas/kas-besar/kas-keluar')
              }"
            >
              <a href="#" class="menu-link menu-toggle">
                <v-img
                  max-width="22"
                  max-height="22"
                  src="../../../../public/assets/icons/icon-cashbank-out.png"
                  class="mr-4 mt-2"
                ></v-img>
                <span class="menu-text">KAS KELUAR</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <!-- router link internal voucher -->
                  <router-link
                    to="/cashbank/large-cashbank/cashbank-out/internal/voucher"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <v-img
                          max-width="22"
                          max-height="22"
                          src="../../../../public/assets/icons/icon-internal.png"
                          class="mr-4 mt-2"
                        ></v-img>
                        <span class="menu-text">INTERNAL</span>
                      </a>
                    </li>
                  </router-link>
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item menu-item-submenu"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/kas/kas-keluar/operational'
                      )
                    }"
                  >
                    <a href="#" class="menu-link menu-toggle">
                      <v-img
                        max-width="22"
                        max-height="22"
                        src="../../../../public/assets/icons/icon-operational.png"
                        class="mr-4 mt-2"
                      ></v-img>
                      <span class="menu-text">OPERASIONAL</span>
                      <i class="menu-arrow"></i>
                    </a>
                    <div class="menu-submenu">
                      <span class="menu-arrow"></span>
                      <ul class="menu-subnav">
                        <!-- router link voucher -->
                        <router-link
                          to="/cashbank/large-cashbank/cashbank-out/operational/voucher"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                          <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active'
                            ]"
                          >
                            <a :href="href" class="menu-link" @click="navigate">
                              <v-img
                                max-width="22"
                                max-height="22"
                                src="../../../../public/assets/icons/icon-voucher.png"
                                class="mr-4 mt-2"
                              ></v-img>
                              <span class="menu-text">VOUCHER</span>
                            </a>
                          </li>
                        </router-link>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <!-- router link large-cashbank -> transaction reports -->
            <router-link
              to="/cashbank/large-cashbank/transaction-reports"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-cashbank-in.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">LAPORAN TRANSAKSI</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
      <!-- router link small-cashbank -->
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/kas/kas')
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <v-img
            max-width="22"
            max-height="22"
            src="../../../../public/assets/icons/icon-small-cashbank.png"
            class="mr-4"
          ></v-img>
          <span class="menu-text">KAS KECIL</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- router link small-cashbank -> cashbank in -->
            <router-link
              to="/cashbank/small-cashbank/cashbank-in/voucher"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-cashbank-in.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">KAS MASUK</span>
                </a>
              </li>
            </router-link>
            <!-- router link small-cashbank -> cashbank out -->
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/kas/kas-kecil/kas-keluar')
              }"
            >
              <a href="#" class="menu-link menu-toggle">
                <v-img
                  max-width="22"
                  max-height="22"
                  src="../../../../public/assets/icons/icon-cashbank-out.png"
                  class="mr-4 mt-2"
                ></v-img>
                <span class="menu-text">KAS KELUAR</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <!-- router link internal voucher -->
                  <router-link
                    to="/cashbank/small-cashbank/cashbank-out/internal/voucher"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <v-img
                          max-width="22"
                          max-height="22"
                          src="../../../../public/assets/icons/icon-internal.png"
                          class="mr-4 mt-2"
                        ></v-img>
                        <span class="menu-text">INTERNAL</span>
                      </a>
                    </li>
                  </router-link>
                  <!-- router link operational submission form -->
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item menu-item-submenu"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/kas/kas-keluar/operational'
                      )
                    }"
                  >
                    <a href="#" class="menu-link menu-toggle">
                      <v-img
                        max-width="22"
                        max-height="22"
                        src="../../../../public/assets/icons/icon-operational.png"
                        class="mr-4 mt-2"
                      ></v-img>
                      <span class="menu-text">OPERASIONAL</span>
                      <i class="menu-arrow"></i>
                    </a>
                    <div class="menu-submenu">
                      <span class="menu-arrow"></span>
                      <ul class="menu-subnav">
                        <!-- router link voucher -->
                        <router-link
                          to="/cashbank/small-cashbank/cashbank-out/operational/voucher"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                          <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active'
                            ]"
                          >
                            <a :href="href" class="menu-link" @click="navigate">
                              <v-img
                                max-width="22"
                                max-height="22"
                                src="../../../../public/assets/icons/icon-voucher.png"
                                class="mr-4 mt-2"
                              ></v-img>
                              <span class="menu-text">VOUCHER</span>
                            </a>
                          </li>
                        </router-link>
                      </ul>
                    </div>
                  </li>
                  <!-- router link admin project submission form -->
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item menu-item-submenu"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/kas/kas-keluar/admin-project'
                      )
                    }"
                  >
                    <a href="#" class="menu-link menu-toggle">
                      <v-img
                        max-width="22"
                        max-height="22"
                        src="../../../../public/assets/icons/icon-admin-project.png"
                        class="mr-4 mt-2"
                      ></v-img>
                      <span class="menu-text">ADMIN PROYEK</span>
                      <i class="menu-arrow"></i>
                    </a>
                    <div class="menu-submenu">
                      <span class="menu-arrow"></span>
                      <ul class="menu-subnav">
                        <!-- router link voucher -->
                        <router-link
                          to="/cashbank/small-cashbank/cashbank-out/admin-project/voucher"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                          <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active'
                            ]"
                          >
                            <a :href="href" class="menu-link" @click="navigate">
                              <v-img
                                max-width="22"
                                max-height="22"
                                src="../../../../public/assets/icons/icon-voucher.png"
                                class="mr-4 mt-2"
                              ></v-img>
                              <span class="menu-text">VOUCHER</span>
                            </a>
                          </li>
                        </router-link>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <!-- router link small-cashbank -> transaction reports -->
            <router-link
              to="/cashbank/small-cashbank/transaction-reports"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-cashbank-in.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">LAPORAN TRANSAKSI</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
      <!-- router link bank-in-out -->
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/bank-in-out/bank-in-out')
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <v-img
            max-width="22"
            max-height="22"
            src="../../../../public/assets/icons/icon-bank-transaction.png"
            class="mr-4"
          ></v-img>
          <span class="menu-text">TRANSAKSI BANK</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- router link bank-in -->
            <router-link
              to="/bank-in-out/bank-in"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-bank-in.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">BANK MASUK</span>
                </a>
              </li>
            </router-link>
            <!-- router link bank-out -->
            <router-link
              to="/bank-in-out/bank-out"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-bank-out.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">BANK KELUAR</span>
                </a>
              </li>
            </router-link>
            <!-- router link bank-guarantee internal -->
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/bank-guarantee/internal')
              }"
            >
              <a href="#" class="menu-link menu-toggle">
                <v-img
                  max-width="22"
                  max-height="22"
                  src="../../../../public/assets/icons/icon-bank-guarantee.png"
                  class="mr-4 mt-2"
                ></v-img>
                <span class="menu-text">BANK GARANSI INTERNAL</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <!-- router link submission-form -->
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item menu-item-submenu"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/bank-guarantee/internal/submission-form'
                      )
                    }"
                  >
                    <a href="#" class="menu-link menu-toggle">
                      <v-img
                        max-width="22"
                        max-height="22"
                        src="../../../../public/assets/icons/icon-submission-form.png"
                        class="mr-4 mt-2"
                      ></v-img>
                      <span class="menu-text">FORM PENGAJUAN</span>
                      <i class="menu-arrow"></i>
                    </a>
                    <div class="menu-submenu">
                      <span class="menu-arrow"></span>
                      <ul class="menu-subnav">
                        <!-- router link submission-form -> operational -->
                        <router-link
                          to="/bank-guarantee/internal/submission-form/operational"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                          <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active'
                            ]"
                          >
                            <a :href="href" class="menu-link" @click="navigate">
                              <v-img
                                max-width="22"
                                max-height="22"
                                src="../../../../public/assets/icons/icon-operational.png"
                                class="mr-4 mt-2"
                              ></v-img>
                              <span class="menu-text">OPERASIONAL</span>
                            </a>
                          </li>
                        </router-link>
                        <!-- router link submission-form -> finance -->
                        <router-link
                          to="/bank-guarantee/internal/submission-form/finance"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                          <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active'
                            ]"
                          >
                            <a :href="href" class="menu-link" @click="navigate">
                              <v-img
                                max-width="22"
                                max-height="22"
                                src="../../../../public/assets/icons/icon-finance.png"
                                class="mr-4 mt-2"
                              ></v-img>
                              <span class="menu-text">KEUANGAN</span>
                            </a>
                          </li>
                        </router-link>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <!-- router link bank-guarantee external -->
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/bank-guarantee/external')
              }"
            >
              <a href="#" class="menu-link menu-toggle">
                <v-img
                  max-width="22"
                  max-height="22"
                  src="../../../../public/assets/icons/icon-bank-guarantee.png"
                  class="mr-4 mt-2"
                ></v-img>
                <span class="menu-text">BANK GARANSI EKSTERNAL</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <!-- router link money out -->
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item menu-item-submenu"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/bank-guarantee/internal/submission-form'
                      )
                    }"
                  >
                    <a href="#" class="menu-link menu-toggle">
                      <v-img
                        max-width="22"
                        max-height="22"
                        src="../../../../public/assets/icons/icon-internal.png"
                        class="mr-4 mt-2"
                      ></v-img>
                      <span class="menu-text">UANG KELUAR</span>
                      <i class="menu-arrow"></i>
                    </a>
                    <div class="menu-submenu">
                      <span class="menu-arrow"></span>
                      <ul class="menu-subnav">
                        <!-- router link submission-form -> operational -->
                        <router-link
                          to="/bank-guarantee/external/internal/submission-form/operational"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                          <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active'
                            ]"
                          >
                            <a :href="href" class="menu-link" @click="navigate">
                              <v-img
                                max-width="22"
                                max-height="22"
                                src="../../../../public/assets/icons/icon-operational.png"
                                class="mr-4 mt-2"
                              ></v-img>
                              <span class="menu-text">FORM PENGAJUAN</span>
                            </a>
                          </li>
                        </router-link>
                        <!-- router link jbs -> internal -->
                        <router-link
                          to="/bank-guarantee/external/internal/voucher"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                          <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active'
                            ]"
                          >
                            <a :href="href" class="menu-link" @click="navigate">
                              <v-img
                                max-width="22"
                                max-height="22"
                                src="../../../../public/assets/icons/icon-internal.png"
                                class="mr-4 mt-2"
                              ></v-img>
                              <span class="menu-text">VOUCHER</span>
                            </a>
                          </li>
                        </router-link>
                      </ul>
                    </div>
                  </li>
                  <!-- router link money in -->
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    class="menu-item menu-item-submenu"
                    v-bind:class="{
                      'menu-item-open': hasActiveChildren(
                        '/bank-guarantee/external/submission-form'
                      )
                    }"
                  >
                    <a href="#" class="menu-link menu-toggle">
                      <v-img
                        max-width="22"
                        max-height="22"
                        src="../../../../public/assets/icons/icon-external.png"
                        class="mr-4 mt-2"
                      ></v-img>
                      <span class="menu-text">UANG MASUK</span>
                      <i class="menu-arrow"></i>
                    </a>
                    <div class="menu-submenu">
                      <span class="menu-arrow"></span>
                      <ul class="menu-subnav">
                        <!-- router link submission-form -> operational -->
                        <router-link
                          to="/bank-guarantee/external/external/submission-form/operational"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                          <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active'
                            ]"
                          >
                            <a :href="href" class="menu-link" @click="navigate">
                              <v-img
                                max-width="22"
                                max-height="22"
                                src="../../../../public/assets/icons/icon-operational.png"
                                class="mr-4 mt-2"
                              ></v-img>
                              <span class="menu-text">FORM PENGAJUAN</span>
                            </a>
                          </li>
                        </router-link>
                        <!-- router link jbs -> internal -->
                        <router-link
                          to="/bank-guarantee/external/external/voucher"
                          v-slot="{ href, navigate, isActive, isExactActive }"
                        >
                          <li
                            aria-haspopup="true"
                            data-menu-toggle="hover"
                            class="menu-item"
                            :class="[
                              isActive && 'menu-item-active',
                              isExactActive && 'menu-item-active'
                            ]"
                          >
                            <a :href="href" class="menu-link" @click="navigate">
                              <v-img
                                max-width="22"
                                max-height="22"
                                src="../../../../public/assets/icons/icon-external.png"
                                class="mr-4 mt-2"
                              ></v-img>
                              <span class="menu-text">VOUCHER</span>
                            </a>
                          </li>
                        </router-link>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
            <!-- router link bank-guarantee -->
            <router-link
              to="/bank-guarantee/guarantee/guarantee"
              v-slot="{ href, navigate, isActive, isExactActive }"
            >
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                class="menu-item"
                :class="[
                  isActive && 'menu-item-active',
                  isExactActive && 'menu-item-active'
                ]"
              >
                <a :href="href" class="menu-link" @click="navigate">
                  <v-img
                    max-width="22"
                    max-height="22"
                    src="../../../../public/assets/icons/icon-bank-guarantee.png"
                    class="mr-4 mt-2"
                  ></v-img>
                  <span class="menu-text">BANK GARANSI</span>
                </a>
              </li>
            </router-link>
          </ul>
        </div>
      </li>
      <!-- router link operational submission form -->
      <li
        aria-haspopup="true"
        data-menu-toggle="hover"
        class="menu-item menu-item-submenu"
        v-bind:class="{
          'menu-item-open': hasActiveChildren('/operational/submission-form')
        }"
      >
        <a href="#" class="menu-link menu-toggle">
          <v-img
            max-width="22"
            max-height="22"
            src="../../../../public/assets/icons/icon-operational.png"
            class="mr-4"
          ></v-img>
          <span class="menu-text">OPERASIONAL</span>
          <i class="menu-arrow"></i>
        </a>
        <div class="menu-submenu">
          <span class="menu-arrow"></span>
          <ul class="menu-subnav">
            <!-- router link submission form -->
            <li
              aria-haspopup="true"
              data-menu-toggle="hover"
              class="menu-item menu-item-submenu"
              v-bind:class="{
                'menu-item-open': hasActiveChildren('/submission-form')
              }"
            >
              <a href="#" class="menu-link menu-toggle">
                <v-img
                  max-width="22"
                  max-height="22"
                  src="../../../../public/assets/icons/icon-submission-form.png"
                  class="mr-4 mt-2"
                ></v-img>
                <span class="menu-text">FORM PENGAJUAN</span>
                <i class="menu-arrow"></i>
              </a>
              <div class="menu-submenu">
                <span class="menu-arrow"></span>
                <ul class="menu-subnav">
                  <!-- router link submission form -> EOP -->
                  <router-link
                    to="/cashbank/large-cashbank/cashbank-out/operational/submission-form"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <v-img
                          max-width="22"
                          max-height="22"
                          src="../../../../public/assets/icons/icon-operational.png"
                          class="mr-4 mt-2"
                        ></v-img>
                        <span class="menu-text">EOP</span>
                      </a>
                    </li>
                  </router-link>
                  <!-- router link submission form -> socialization -->
                  <router-link
                    to="/cashbank/small-cashbank/cashbank-out/operational/submission-form"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <v-img
                          max-width="22"
                          max-height="22"
                          src="../../../../public/assets/icons/icon-operational.png"
                          class="mr-4 mt-2"
                        ></v-img>
                        <span class="menu-text">SOSIALISASI</span>
                      </a>
                    </li>
                  </router-link>
                  <!-- router link submission form -> administration -->
                  <router-link
                    to="/cashbank/small-cashbank/cashbank-out/admin-project/submission-form"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <v-img
                          max-width="22"
                          max-height="22"
                          src="../../../../public/assets/icons/icon-operational.png"
                          class="mr-4 mt-2"
                        ></v-img>
                        <span class="menu-text">ADMINISTRASI</span>
                      </a>
                    </li>
                  </router-link>
                  <!-- router link submission form -> field activities -->
                  <router-link
                    to="/field-activities"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <v-img
                          max-width="22"
                          max-height="22"
                          src="../../../../public/assets/icons/icon-operational.png"
                          class="mr-4 mt-2"
                        ></v-img>
                        <span class="menu-text">KEGIATAN LAPORAN</span>
                      </a>
                    </li>
                  </router-link>
                  <!-- router link submission form -> history -->
                  <router-link
                    to="/operational-history"
                    v-slot="{ href, navigate, isActive, isExactActive }"
                  >
                    <li
                      aria-haspopup="true"
                      data-menu-toggle="hover"
                      class="menu-item"
                      :class="[
                        isActive && 'menu-item-active',
                        isExactActive && 'menu-item-active'
                      ]"
                    >
                      <a :href="href" class="menu-link" @click="navigate">
                        <v-img
                          max-width="22"
                          max-height="22"
                          src="../../../../public/assets/icons/icon-operational.png"
                          class="mr-4 mt-2"
                        ></v-img>
                        <span class="menu-text">RIWAYAT PEJABAT</span>
                      </a>
                    </li>
                  </router-link>
                </ul>
              </div>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import localStorage from "@/core/services/store/localStorage";

export default {
  name: "KTMenu",
  data: () => ({
    data_distribusi: [],
    submitted: false,
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    isFirstLoad: true,
    label: "",
    placeholder: "",
    readonly: false,
    disabled: false,
    outlined: false,
    clearable: false,
    valueWhenIsEmpty: "",
    notifikasi_ayam: 0,
    notifikasi_ceker: 0,
    user_role: ""
  }),

  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    }
  },

  watch: {
    options: {
      async handler(val) {
        val;
        if (!this.isFirstLoad) await this.dataDistribusi();
        this.loading = false;
      },
      deep: true
    }
  },

  created() {},

  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    }
  },
  mounted() {
    this.user_role = localStorage.getLocalStorage("User_Role");
  }
};
</script>
